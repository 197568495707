const hokkaidoTeams = [
  { name: '札幌ギャンブラーズ', url: 'http://sgambler.hp.infoseek.co.jp/index.shtml' },
  { name: 'クルムス・イーグルス', url: 'http://krmseagles.web.fc2.com/' },
  { name: 'ノースカウボーイズ', url: 'http://north-wolves.homelinux.com/' },
  { name: '札幌ベンガルズ', url: 'http://www.geocities.co.jp/Athlete-Crete/5908/' },
]

const tohokuTeams = [
  { name: '盛岡ラウディーズ', url: '' },
  { name: '仙台ブラックボルツ', url: '' },
  { name: '福島ピッグスキンズ', url: 'http://www2.ocn.ne.jp/~pigskins/' },
  { name: '秋田ラムズ', url: 'http://www.geocities.jp/akitarams/' },
  { name: '弘前グリーンサージェント', url: 'http://sergeant.main.jp/' },
]

const aomoriTeams = [
  { name: '八戸ルナティックス	', url: 'http://www.geocities.co.jp/Athlete-Olympia/7677/' },
  { name: '弘前グリーンサージェント', url: 'http://sergeant.main.jp/' },
]

const hokurikuTeams = [
  { name: '富山ベアーズ', url: 'http://www.geocities.co.jp/Athlete-Olympia/5291/' },
  { name: '金沢ワイセンベルグ', url: 'http://www.geocities.jp/kanazawa_weissenberg/index.html' },
  { name: '福井スノースイーパーズ', url: 'http://www.geocities.co.jp/Athlete-Rodos/5662/' },
]

const shikokuTeams = [
  { name: '川之江ジェッツ', url: 'http://www5.inforyoma.or.jp/~ryukosan/' },
  { name: '高松ベアーズ', url: 'http://kln.ne.jp/nao-nao/bears.htm' },
  { name: '呉ガルフエンジェルス', url: 'http://ww4.tiki.ne.jp/~otsubo/' },
  { name: '徳島レッドインパルス', url: 'http://www.yo.rim.or.jp/~nakanor/football/' },
  { name: '愛媛オレンジメン', url: 'http://orangemen.hp.infoseek.co.jp/' },
]

const kyusyuTeams = [
  { name: 'オクトバーベアーズ', url: 'http://www1.nisiq.net/~big-step/' },
  { name: 'C.A.コルベッツ', url: '' },
  { name: 'ユニコーン', url: 'http://www.team-unicorn.jp/' },
  { name: '熊本マーベリックス', url: '' },
  { name: '福岡パイレーツ', url: '' },
  { name: '太陽家具サンファイターズ', url: '' },
  { name: '福岡シーホークス', url: '' },
  { name: '鹿児島サザンロケッツ', url: '' },
  { name: '宮崎スカイフェニックス', url: 'http://www.geocities.co.jp/Athlete-Crete/3521/' },
  { name: '福岡レイダース', url: '' },
  { name: 'ブルーアトミックス', url: 'http://f1.aaa.livedoor.jp/~blueatom/' },
  { name: '佐世保タイタンズ', url: 'http://www.geocities.jp/miya32981/' },
]

const relatedLinks = [
  { name: '日本アメリカンフットボール協会', url: 'https://americanfootball.jp/' },
  { name: '九州社会人アメリカンフットボール連盟', url: 'https://ksfl1982.wixsite.com/ksfl1982' },
  { name: '関東フラッグフットボールリーグ', url: 'http://kantoh-ffl.sakura.ne.jp/' },
  { name: 'キュービィクラブ', url: 'https://www.qbclubstore.com/' },
  { name: '江崎グリコ株式会社', url: 'https://www.glico.com/' },
  { name: 'ファイテン', url: 'https://www.phiten.com/' },
  { name: '株式会社フォトクリエイト', url: 'https://www.photocreate.co.jp/' },
  { name: 'コロンビアスポーツウェアジャパン', url: 'https://www.columbiasports.co.jp/' },
  { name: '志太スタジアム', url: 'https://www.shidax.co.jp/winery/sports/facility.html' },
  { name: 'チアクリエーション', url: 'http://cheer-c.jp/' },
]

export { hokkaidoTeams, tohokuTeams, aomoriTeams, hokurikuTeams, shikokuTeams, kyusyuTeams, relatedLinks }
