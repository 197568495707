<template>
  <v-simple-table>
      <template v-slot:default>
      <thead>
          <tr>
          <th>名前</th>
          <th>URL</th>
          </tr>
      </thead>
      <tbody>
          <tr
          v-for="team in teams"
          :key="team.name"
          >
          <td class='text-no-wrap'>{{ team.name }}</td>
          <td class='text-no-wrap'>
            <ExternalLink v-if='team.url' :label='team.url' :url='team.url' />
          </td>
          </tr>
      </tbody>
      </template>
  </v-simple-table>
</template>

<script>
import ExternalLink from '@/components/ExternalLink'

export default {
  props: [ 'teams' ],
  components: { ExternalLink },
}
</script>
